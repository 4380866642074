<template>
  <v-autocomplete
    v-model="status"
    :items="statuses[type]"
    :label="$tc('status', 1) | capitalize"
    xoutlined
    dense
    multiple
    small-chips
    clearable
    menu-props="offsetY"
    @change="$emit('update:value', status)"
  >
    <template #prepend-inner>
      <v-icon
        small
        class="mt-1"
        color="grey"
      >
        fal fa-badge-check
      </v-icon>
    </template>
    <template #item="{ item }">
      <status-chip
        :key="item.status"
        :status="item"
        :type="type"
        block
      />
    </template>
    <template #selection="{ item }">
      <status-chip
        :status="item"
        :type="type"
        class="ml-0"
      />
    </template>
  </v-autocomplete>
</template>
<script>
  import Vue2Filters from 'vue2-filters'

  export default {
    components: {
      StatusChip: () => import('@/components/StatusChip.vue'),
    },
    mixins: [
      Vue2Filters.mixin,
    ],
    props: {
      value: {
        type: [Array, String],
        default: () => [],
      },
      type: {
        type: String,
        default: 'lab results',
      },
    },
    data () {
      return {
        loading: false,
        status: this.value,
      }
    },
    computed: {
      statuses: function () {
        if (this.$auth.user().client) {
          return {
            'one choice reports': [
              'complete',
              'in progress',
              'flagged',
            ],
            prescriptions: [
              'active',
              'inactive',
            ],
          }
        } else {
          return {
            'lab results': [
              'new',
              'data extraction',
              'high confidence',
              'quality assurance',
              'pending approval',
              'interpreted',
              'negative',
              'do not interpret',
              'unclassified data',
              'needs review',
              'needs translation',
              'contact lab',
              'tech support',
              'partial data',
            ],
            prescriptions: [
              'active',
              'inactive',
            ],
            reports: [
              'pending approval',
              'needs review',
              'ready',
            ],
          }
        }
      },
    },
    watch: {
      value () {
        this.status = this.value
      },
    },
  }
</script>
